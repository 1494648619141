<template>
    <div>
        <HeaderBg title="اللافتات" />
        <div class="form_container" v-if="this.$route.params.id == 1">
            <form @submit.prevent="addMainBanner">
                <div class="upload_img">
                    <span>  الصورة :</span>
                    <div class="img_container">
                        <img src="../../../assets/images/replaceImg.svg" alt="" v-if="main_banner_img_url == ''">
                        <img :src="main_banner_img_url" alt="" v-if="main_banner_img_url !== ''">
                        <div class="photo_upload">
                            <div class="upladImg">
                                <div class="upload">
                                    <label for="">
                                        <img src="../../../assets/images/inputFile.svg" alt="">
                                        <input type="file" v-on="{ change: [uploadMainBannerImg] }">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input_container">
                    <label>  العنوان باللغة العربية :</label>
                    <input type="text" v-model="main_banner.translation[0].name">
                </div>
                <div class="input_container mt-3">
                    <label>  العنوان باللغة الإنجليزية :</label>
                    <input type="text" v-model="main_banner.translation[1].name">
                </div>
                <div class="input_container mt-3">
                    <label>  رابط اللافتة :</label>
                    <input type="text" v-model="main_banner.url">
                </div>
                

                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>

        <div class="form_container" v-if="this.$route.params.id == 2">
            <form @submit.prevent="addAdsBanner">
                <div v-for="(ads, index) in adsBanner" :key="index" class="ads_container mb-2">
                    <div class="upload_img">
                        <span>  الصورة :</span>
                        <div class="img_container">
                            <img src="../../../assets/images/replaceImg.svg" alt="" v-if="ads.showImage == ''">
                            <img :src="ads.showImage" alt="" v-if="ads.showImage !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadadsBannerImg(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input_container">
                        <label> 1 رابط اللافتة :</label>
                        <input type="text" v-model="ads.url">
                    </div>
                    <button class="cancelBtn" @click="() => deleteAds(index, ads.id)"> مسح اللافتة </button>
                </div>

                <div class="add_new" @click="addAds">
                    <div class="blus_icon">+</div>
                    إضافة لافتة اعلانية اخرى 
                </div>
                

                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>

        <div class="form_container full_container" v-if="this.$route.params.id == 3">
            <form @submit.prevent="updateOfferBanner">
                <div class="bannerAddNew" v-for="(banner, index) in OfferBanner" :key="index">
                    <div class="upload_img">
                        <span>  الصورة الأولي :</span>
                        <div class="img_container">
                            <img src="../../../assets/images/replaceImg.svg" alt="" v-if="banner.showImage1 == ''">
                            <img :src="banner.showImage1" alt="" v-if="banner.showImage1 !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadOfferBannerImg1(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="banner_title"></div>
                    <div class="banner_title">
                        البانر الأول
                    </div>
                    <div class="input_container">
                        <label> : الرابط الأول </label>
                        <input type="text" v-model="banner.first_url">
                    </div>
                    <div class="input_container">

                    </div>
                    <div class="input_container">
                        <label> : الإسم الاول باللغة العربية</label>
                        <input type="text" v-model="banner.first_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الأول باللغة الإنجليزية </label>
                        <input type="text" v-model="banner.first_name_en">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثاني باللغة العربية</label>
                        <input type="text" v-model="banner.second_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثاني باللغة الإنجليزية </label>
                        <input type="text" v-model="banner.second_name_en">
                    </div>
                    <div class="banner_title">
                        البانر الثاني
                    </div>
                    <div class="upload_img">
                        <span>  الصورة الثانية :</span>
                        <div class="img_container">
                            <img src="../../../assets/images/replaceImg.svg" alt="" v-if="banner.showImage2 == ''">
                            <img :src="banner.showImage2" alt="" v-if="banner.showImage2 !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadOfferBannerImg2(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input_container"></div>
                    <div class="input_container">
                        <label> : الرابط الثاني </label>
                        <input type="text" v-model="banner.second_url">
                    </div>
                    <div class="input_container">
                        
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثالث باللغة العربية </label>
                        <input type="text" v-model="banner.third_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثالث باللغة الانجليزية</label>
                        <input type="text" v-model="banner.third_name_en">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الرابع باللغة العربية </label>
                        <input type="text" v-model="banner.fourth_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الرابع باللغة الإنجليزية </label>
                        <input type="text" v-model="banner.fourth_name_en">
                    </div>

                </div>
                <div class="add_new" @click="addOfferBanner">
                    <div class="blus_icon">+</div>
                    إضافة لافتة اعلانية اخرى 
                </div>
                
               <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>

        <div class="form_container" v-if="this.$route.params.id == 4">
            <form @submit.prevent="addMainBannerPage">
                <div class="upload_img">
                    <span>  الصورة :</span>
                    <div class="img_container">
                        <img src="../../../assets/images/replaceImg.svg" alt="" v-if="main_banner_page_img_url == ''">
                        <img :src="main_banner_page_img_url" alt="" v-if="main_banner_page_img_url !== ''">
                        <div class="photo_upload">
                            <div class="upladImg">
                                <div class="upload">
                                    <label for="">
                                        <img src="../../../assets/images/inputFile.svg" alt="">
                                        <input type="file" v-on="{ change: [uploadMainBannerPageImg] }">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
import axios from 'axios';
import store from "@/store/index.js";
export default {
    name: 'AddPannars',
    components: {HeaderBg},
    data(){
        return{
            errors: [],
            ErrorCheck: false,
            postLoaded: false,
            loadingRequest: false,
            deleteID: '',
            alertToggle: false,
            main_banner_img_url:'',
            main:{
                image: null
            },

            main_banner:{
                translation : [
                    {
                        name : "",
                        local : "ar"
                    },
                    {
                        name : "",
                        local : "en"
                    },
                ],
                image: null,
                url: ''
            },

            adsBanner: [
                // {
                //     image : null,
                //     url: '',
                //     showImage: ''
                // }
            ],

            OfferBanner: [

            ],

            main_banner_page_img_url: '',
            main_banner_page_image: null,


            
        }
    },
    mounted(){
      this.getMainBanner();
      this.getAdsBanner();
      this.getOffersBanner();
      this.getMainBannerPage();
    },
    methods:{
        // main banner
        uploadMainBannerImg(e) {
            this.main_banner.image = e.target.files[0];
            this.main_banner_img_url = URL.createObjectURL(e.target.files[0]);
        },
        addMainBanner(){
            this.postLoaded = true
            const formData = new FormData();
            
            formData.append('translation[0][name]', this.main_banner.translation[0].name);
            formData.append('translation[0][local]', this.main_banner.translation[0].local);
            formData.append('translation[1][name]', this.main_banner.translation[1].name);
            formData.append('translation[1][local]', this.main_banner.translation[1].local);
            formData.append('image', this.main_banner.image);
            formData.append('url', this.main_banner.url);
            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/mainBanner/2`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },
        getMainBanner(){
            axios.get(`${store.state.domain}/admin/mainBanner`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {  
                this.main_banner.translation[0].name = response.data.data.translation[0].name
                this.main_banner.url = response.data.data.url
                this.main_banner_img_url = response.data.data.image
                this.main_banner.translation[1].name = response.data.data.translation[1].name
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
        uploadUserImg(e) {
            this.user.image = e.target.files[0];
            this.imgUrl = URL.createObjectURL(e.target.files[0]);
        },

        // ads banner
        addAdsBanner(){
            this.postLoaded = true
            const formData = new FormData();

            if(this.adsBanner !== []){
                this.adsBanner.map((data, index) => {
                    formData.append(`ads[${index}][image]`, this.adsBanner[index].image);
                    formData.append(`ads[${index}][url]`, this.adsBanner[index].url);
                    formData.append(`ads[${index}][id]`, this.adsBanner[index].id);
                })
            }

            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/updateAdsBanner/e211kkk2`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },
        getAdsBanner(){
            axios.get(`${store.state.domain}/admin/showAdsBanner/e211kkk2`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                console.log(response, 'main')
                response.data.data.map(data => {
                    this.adsBanner.push(
                        {
                            image : null,
                            url: data.url,
                            showImage: data.image,
                            uuid: data.uuid,
                            id: data.id
                        }
                    )
                })
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
        uploadadsBannerImg(e, index){
            console.log(e, index)
            this.adsBanner[index].image = e.target.files[0];
            this.adsBanner[index].showImage = URL.createObjectURL(e.target.files[0]);
        },
        
        addAds(){
            this.adsBanner.push(
                {
                    image : null,
                    url: '',
                    showImage: ''
                }
            )
        },
        deleteAds(index, id){
            this.adsBanner.splice(index, 1)

            Request.delete('admin/deleteAdsBanner',id)
            .then( () => {
                console.log('success')
            })
        },

        addOfferBanner(){
            this.OfferBanner.push(
                {
                    first_image: null,
                    first_url: '',
                    second_image: null,
                    second_url: '',
                    id: '',
                    first_name_ar: '',
                    first_name_en: '',
                    second_name_ar: '',
                    second_name_en: '',
                    third_name_ar: '',
                    third_name_en: '',
                    fourth_name_ar: '',
                    fourth_name_en: '',
                    showImage1: '',
                    showImage2: ''
                }
            )
        },
        uploadOfferBannerImg1(e, index){
            this.OfferBanner[index].first_image = e.target.files[0];
            this.OfferBanner[index].showImage1 = URL.createObjectURL(e.target.files[0]);
        },
        uploadOfferBannerImg2(e, index){
            this.OfferBanner[index].second_image = e.target.files[0];
            this.OfferBanner[index].showImage2 = URL.createObjectURL(e.target.files[0]);
        },

        updateOfferBanner(){
            this.postLoaded = true
            const formData = new FormData();

            if(this.OfferBanner !== []){
                this.OfferBanner.map((data, index) => {
                    if(this.OfferBanner[index].first_image !== null) {
                        formData.append(`offers[${index}][first_image]`, this.OfferBanner[index].first_image);
                    }
                    if(this.OfferBanner[index].second_image !== null) {
                        formData.append(`offers[${index}][second_image]`, this.OfferBanner[index].second_image);
                    }
                    
                    formData.append(`offers[${index}][first_url]`, this.OfferBanner[index].first_url);
                    formData.append(`offers[${index}][second_url]`, this.OfferBanner[index].second_url);
                    formData.append(`offers[${index}][id]`, this.OfferBanner[index].id);
                    formData.append(`offers[${index}][first_name_ar]`, this.OfferBanner[index].first_name_ar);
                    formData.append(`offers[${index}][first_name_en]`, this.OfferBanner[index].first_name_en);
                    formData.append(`offers[${index}][second_name_ar]`, this.OfferBanner[index].second_name_ar);
                    formData.append(`offers[${index}][second_name_en]`, this.OfferBanner[index].second_name_en);
                    formData.append(`offers[${index}][third_name_ar]`, this.OfferBanner[index].third_name_ar);
                    formData.append(`offers[${index}][third_name_en]`, this.OfferBanner[index].third_name_en);
                    formData.append(`offers[${index}][fourth_name_ar]`, this.OfferBanner[index].fourth_name_ar);
                    formData.append(`offers[${index}][fourth_name_en]`, this.OfferBanner[index].fourth_name_en);
                })
            }

            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/updateOfferBanner/eeaa2222`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },

        getOffersBanner(){
            axios.get(`${store.state.domain}/admin/offerBanner/eeaa2222`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                console.log(response, 'offfff')
                response.data.data.map(data => {
                    this.OfferBanner.push(
                        {
                            first_image : null,
                            first_url: data.first_url,
                            second_image: null,
                            second_url: data.second_url,
                            id: data.id,
                            first_name_ar: data.first_name_ar,
                            first_name_en: data.first_name_en,
                            second_name_ar: data.second_name_ar,
                            second_name_en: data.second_name_en,
                            third_name_ar: data.third_name_ar,
                            third_name_en: data.third_name_en,
                            fourth_name_ar: data.fourth_name_ar,
                            fourth_name_en: data.fourth_name_en,
                            showImage1: data.first_image,
                            showImage2: data.second_image,
                        }
                    )
                })
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
    
        uploadMainBannerPageImg(e) {
            this.main_banner_page_image = e.target.files[0];
            this.main_banner_page_img_url = URL.createObjectURL(e.target.files[0]);
        },
        getMainBannerPage(){
            axios.get(`${store.state.domain}/admin/offerHeader`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {  
                this.main_banner_page_img_url = response.data.data.image 
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
        addMainBannerPage(){
            this.postLoaded = true
            const formData = new FormData();
            formData.append('image', this.main_banner_page_image);
            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/offerHeader/1`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 20px;
    width: 482px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    .ads_container{
        border: 1px solid #DDD;
        padding: 10px;
    }
    .cancelBtn{
        background-color: red;
        color: #FFF;
    }
    form{
        .input_container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
                width: 179px;
                margin-left: 30px;
            }
            input,
            select{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 100%;
            }
        }
        .saveBtn{
            margin: 30px auto 0 auto;   
            display: block;
        }
    }
    .add_new{
        color: #78A28F;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin: 20px 0;
        cursor: pointer;
        .blus_icon{
            border: 2px solid #78A28F;
            padding: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.full_container{
    width: 80%;

    form > .bannerAddNew{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .input_container,
    .upload_img{
        width: 45%;
        margin-bottom: 30px;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .banner_title{
        width: 100%;
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        margin: 20px 0;
    }
}

.upload_img{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            span{
                font-size: 16px;
                color: #656565;
                margin-left: 50px;
            }
            .img_container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #FAFAFA;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img{
                    width: 100%;
                }
                .photo_upload{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    img{
                        width: 70%;
                    }
                }
            }
        }
</style>