<template>
    <div>
        <HeaderBg :img="img" title="الوصفات الطبية" :links="links" />
        <header class="admin_content_header">
            <!-- <div class="filter">
                <select>
                    <option value="" selected disabled>المدينة</option>
                </select>
                <div class="tabs_filter">
                    <div class="active_tap">الكل</div>
                    <div>الأكثر شراء</div>
                    <div>النقاط</div>
                </div>
            </div> -->
            <div></div>
            <div class="search">
                <form @submit.prevent="filterPrescription">
                    <div class="inputSearch">
                        <input type="text" v-model="filters.name" placeholder="بحث بالإسم">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>ً
                </form>
            </div>
            <div></div>
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="request_table_container">
            <div class="detailsTable" v-if="loadingRequest == false">
                <div class="table_header">
                    <div class="block">
                        رقم الطلب
                    </div>
                    <div class="block">
                        معلومات الاتصال
                    </div>
                    <div class="block">
                        الفرع
                    </div>
                    <div class="block">
                        الحالة
                    </div>
                    <div class="block">

                    </div>
                    <div class="block">

                    </div>
                </div>
                <div class="table_row" v-for="(prescription) in prescriptions" :key="prescription.id">
                    <div class="block">
                        <span>#{{prescription.id}}</span>
                    </div>
                    <div class="block">
                        <h5>{{prescription.user}}</h5>
                        <h3>{{prescription.mobile}}</h3>
                    </div>
                    <div class="block">
                        <h3>{{prescription.branch !== '' ?  prescription.branch : 'لا يوجد'}}</h3>
                    </div>
                    <div class="block">
                        <h4 class="Processing" v-if="prescription.status.name == 'قيد التنفيذ'">{{prescription.status.name}}</h4>
                        <h4 class="deliverd" v-if="prescription.status.name == 'مقبول'">{{prescription.status.name}}</h4>
                        <h4 class="cancel" v-if="prescription.status.name == 'مرفوض'">{{prescription.status.name}}</h4>
                        <select @change="(e) => updateStatus(e, prescription.id)">
                            <option value="" selected disabled>{{prescription.status.name}}</option>
                            <option :value="status.id" v-for="status in orderStatus" :key="status.id"> {{status.translation[0].name}} </option>
                        </select>
                        <h3>{{prescription.date}}</h3>
                    </div>
                    <div class="block">
                        <button class="saveBtn" @click="() => addToCart(prescription.user_id)">
                            إضافة المنتجات
                        </button>
                    </div>
                    <div class="block">
                        <a class="saveBtn" :href="prescription.download_url  + '?token=' + accessToken">
                            <img src="../../../assets/images/downloadSvg2.svg" alt="">
                            تنزيل المرفقات
                        </a>
                    </div>
                </div>

                <div class="add_product_to_user_cart_popUp_container" v-if="addToCartPopUp == true">
                    <div class="popUp">
                        <b-icon class="closePopUp" icon="x" aria-hidden="true" @click="closePopUp"></b-icon>
                        <h3>إضافة العلاج في سلة العميل</h3>

                        <input type="text" v-model="product_name" @change="NameFilter" placeholder="إبحث بأسم المنتج">

                        <div class="products_container">
                            <div class="single_product" v-for="product in products" :key="product.id" @click="() => addProduct(product.id, product.translation[0].name)">
                                <span>{{product.translation[0].name}}</span>
                                <span>{{product.id}}</span>
                            </div>
                        </div>

                        <div class="products_container chosen_products">
                            <div class="single_product" v-for="(product, index) in choosenProducts" :key="product.id">
                                <div>
                                    <span>{{product.name}}</span>
                                    <span>{{product.product_id}}</span>
                                </div>
                                <div class="count">
                                    <div class="plus" @click="() => changeCount('+', index)">
                                        +
                                    </div>
                                    <div class="number">
                                        {{product.amount}}
                                    </div>
                                    <div class="min" @click="() => changeCount('-', index)">
                                        -
                                    </div>
                                </div>
                                <div class="deleteProduct" @click="deleteProduct(index)">
                                    <b-icon  icon="x" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                            <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                        </div>
                        <button @click="addToClintCart" v-if="postLoaded == false">إضافة إلي سلة العميل</button>
                        <button v-if="postLoaded == true"><b-spinner></b-spinner></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination mt-5" v-if="prescriptions.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
// import VueTagsInput from '@johmun/vue-tags-input';
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import Request from '../../../services/Request';
import store from "@/store/index.js";
export default {
    name: 'Prescriptions',
    components: {HeaderBg, RequestSpinner},
    data(){
        return{
            postLoaded: false,
            loadingRequest: true,
            details: false,
            addToCartPopUp: false,

            tag: '',
            tags: [],
            autocompleteItems: [],

            products: [],
            choosenProducts: [],

            img: require('../../../assets/images/prescriptionLarg.png'),
            links: [
                {
                    to:"/store-admin/prescriptions/mine",
                    link: 'وصفاتي',
                },
                {
                    to:"/store-admin/prescriptions/cash",
                    link: 'نقدا',
                    active: 'active'
                },
                {
                    to:"/store-admin/prescriptions/insurance",
                    link: 'تأمين'
                }
            ],
            prescriptions: [],
            orderStatus: [],

            product_name: '',

            errors: [],
            ErrorCheck: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,

            filters:{
                name: null,
                type: 'cash',
                limit: store.state.limit
            },
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }

        this.accessToken = localStorage.getItem('token')
        this.getPrescriptions();
        this.getOrderStatus();
        this.getProducts();
    },
    methods:{
        deleteProduct(id){
            this.choosenProducts.splice(id, 1)
        },
        addToClintCart(){
            this.postLoaded = true;
            axios.post(`https://app.almujtama.com.sa/admin/cart`, {
                user_id : this.userID,
                product : this.choosenProducts
            }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.addToCartPopUp = false
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.errors = err.response.data.errors;
                this.ErrorCheck = true;
                this.postLoaded = false;
                
            })
        },
        changeCount(operation, length){
            this.choosenProducts.map((data, index) => {
                if(length == index){
                    if(operation == '+'){
                        data.amount = data.amount + 1
                    } else{
                        data.amount = data.amount - 1
                    }
                }
            })
        },
        addToCart(id){
            this.userID = id
            this.addToCartPopUp = true
        },
        addProduct(id, name){
            this.choosenProducts.push({
                product_id: id,
                name: name,
                amount: 1
            })
        },
        closePopUp(){
            this.addToCartPopUp = false
        },
    
        NameFilter(){
            Request.getFilteredData('api/product', {
                name: this.product_name
            })
            .then(res => {
                this.products = res.data.data
            })
        },
        openDetails(){
            this.details = !this.details
        },

        getPrescriptions(){
            Request.fetch('admin/prescriptions', this.apiParams , {
                limit: store.state.limit,
                type: 'cash'
            })
            .then((response) => {
                this.prescriptions = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        downloadfile(e) {
            const url = window.URL.createObjectURL(new Blob([e]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.pdf");
            document.body.appendChild(link);
            link.click();
        },
        getOrderStatus(){
            axios.get(`https://app.almujtama.com.sa/admin/orderStatus`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                }
            })
            .then((response) => {
                this.orderStatus = response.data.data
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        updateStatus(e, id){
            Request.put('admin/updateStatus',id, {
                type : "prescription",
                status : e.target.value
            })
            .then( () => {
                this.getPrescriptions();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
        filterPrescription(){
            if(this.filters.name == ''){
                this.filters.name = null
            }
            this.loadingRequest = true;
            Request.fetch('admin/prescriptions', this.apiParams , this.filters)
            .then((response) => {
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
                this.prescriptions = response.data.data 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getPrescriptions();
            } else{
                this.apiParams = `?page=${val}`;
                this.getPrescriptions();
            }
        }
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
    },
}
</script>
<style lang="scss" scoped>

.Processing{
    color: #EEAF33;
}
.deliverd{
    color: #42FF68;
}
.cancel{
    color: #FF3D3D;
}
.table_header{
    background-color: #FFF;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px;
    .block{
        width: calc(100% / 4);
        text-align: center;
        padding: 15px 0;
        font-weight: 600;
    }
}
.table_row{
    flex-wrap: wrap;
}
.block{
    width: calc(100% / 7);
    select{
        border: 0;
        font-size: 16px;
        cursor: pointer;
        option{
            color: #000;
        }
    }
}
.details_container{
    width: 100%;
    padding: 10px 40px;
    .table_header{
        .block{
            color: #1D4D90;
            font-weight: 900;
            font-family: flatMedium;
            width: calc(100% / 4) !important;        
        }
    }
    .table_row{
        .block{
            width: calc(100% / 5) !important;
        }
    }
}

.saveBtn{
    display: flex;
    align-items: center;
    font-size: 16px;
    width: auto;
    padding: 10px;
    img{
        width: 20px;
        margin-left: 10px;
    }
}


</style>