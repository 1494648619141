<template>
    <div>
        <HeaderBg :img="img" title="المستخدمين" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteUser" 
            messege="هل أنت متأكد من مسح المستخدم ؟"
        />
        <header class="admin_content_header">
            <div></div>
            <!-- <div class="filter">
                <select>
                    <option value="" selected disabled>المدينة</option>
                </select>
                <div class="tabs_filter">
                    <div class="active_tap">الكل</div>
                    <div>الأكثر شراء</div>
                    <div>النقاط</div>
                </div>
            </div>
            <div class="search">
                <input type="text" placeholder="البحث  ">
            </div> -->
            <button @click="openAddUser" v-if="add_users_permission == true">  + إضافة مستخدم </button>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الاسم</th>
                        <th> رقم الجوال</th>
                        <th> البريد</th>
                        <th> الصلاحية</th>
                        <th> الحالة </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="admin in admins" :key="admin.id">
                        <td> {{admin.name}} </td>
                        <td> {{admin.mobile}} </td>
                        <td> {{admin.email}} </td>
                        <td> {{admin.role[0]}} </td>
                        <td> {{admin.active == 0 ? 'غير مفعل' : 'مفعل'}} </td>
                        <td>
                            <div class="options_container"  v-if="edit_users_permission == true || delete_users_permission == true">
                                <img src="../../../assets/images/selectIcon.png" alt="">
                                <div class="hidden_options">
                                    <button v-if="edit_users_permission == true" @click="() => editUser(admin.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                    <button v-if="delete_users_permission == true" @click="() => deleteData(admin.id)"> <img src="../../../assets/images/delete-text.png" alt="" > حذف </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="admins.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>

            <div class="add_user_container" v-if="addUser == true">
                <div class="add_user">
                    <div class="header">
                        <h3>{{popUp_type == 'edit' ? ' تعديل المستخدم' : 'اضافة مستخدم جديد ' }}</h3>
                        <b-icon icon="x" aria-hidden="true" @click="addUser = !addUser"></b-icon>
                    </div>
                    <div class="body">
                            <div class="input_container">
                                <label>اسم المستخدم</label>
                                <input type="text" v-model="newUser.name">
                            </div>
                            <div class="input_container">
                                <label>رقم الجوال</label>
                                <input type="phone" v-model="newUser.mobile">
                            </div>
                            <div class="input_container">
                                <label>البريد الإلكتروني</label>
                                <input type="email" v-model="newUser.email">
                            </div>
                            <div class="input_container">
                                <label>كلمة المرور</label>
                                <input type="password" v-model="newUser.password">
                            </div>
                            <div class="input_container">
                                <label>الصلاحية</label>
                                <select v-model="newUser.role_id">
                                    <option :value="role.id" v-for="role in roles" :key="role.id">{{role.name}}</option>
                                </select>
                            </div>
                            <div class="input_container">
                                <label>الرقم الوظيفي</label>
                                <input type="number" v-model="newUser.employment_number">
                            </div>
                            <div class="input_container">
                                <label>فرع الصيدلية</label>
                                <select v-model="newUser.branch_id">
                                    <option :value="branch.id" v-for="branch in branches" :key="branch.id">{{branch.translation[0].name}}</option>
                                </select>

                                <!-- <multiselect  deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select one" :options="options" :searchable="false" :allow-empty="false">
    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> is written in<strong>  {{ option.language }}</strong></template>
  </multiselect> -->
                            </div>
                            <div class="alert alert-danger" role="alert" v-if="ErrorCheck == true">
                                <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                            </div>
                            <div class="action d-flex justify-content-center w-100">
                                <button class="saveBtn"  v-if="postLoaded == false" @click="addNewUser">
                                {{popUp_type == 'edit' ? ' تعديل' : 'إضافة' }}
                        </button>
                        <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store/index.js";
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import axios from 'axios';
// import Multiselect from 'vue-multiselect'
export default {
    name: 'AdminUsers',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            postLoaded: false,
            img: require('../../../assets/images/GroupWhite.png'),
            users: [],
            deleteID: '',
            alertToggle: false,
            addUser: false,
            admins:[],
            roles:[],
            branches:[],
            // options:[],
            options: [
                { name: 'Vue.js', language: 'JavaScript' },
                { name: 'Rails', language: 'Ruby' },
                { name: 'Sinatra', language: 'Ruby' },
                { name: 'Laravel', language: 'PHP', $isDisabled: true },
                { name: 'Phoenix', language: 'Elixir' }
            ],
            newUser:{
                name : "",
                role_id : "",
                mobile : "",
                email : "",
                password : "",
                employment_number : "",
                branch_id : ""
            },
            popUp_type: 'add',
            user_id: null,

            // permissions
            add_users_permission: false,
            edit_users_permission: false,
            delete_users_permission: false,

            errors: [],
            ErrorCheck: false,

            selectedOption: '',

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list users')){
            console.log('permission')
        } else {
            this.$router.push('/login')
        }

        if(permissions.includes('add users')){
            this.add_users_permission = true
        } 
        if(permissions.includes('update users')) {
            this.edit_users_permission = true
        } 
        if(permissions.includes('delete users')) {
            this.delete_users_permission = true
        } 
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getAdmins();
        this.getRoles();
        this.getBranches();
    },
    methods:{
        editUser(id){
            this.addUser = true
            this.popUp_type = 'edit'
            this.user_id = id

            axios.get(`https://app.almujtama.com.sa/admin/admins/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.newUser = response.data.data
                this.newUser.name = response.data.data.name
                this.newUser.role_id = response.data.data.role[0].id
                this.newUser.mobile = response.data.data.mobile
                this.newUser.email = response.data.data.email
                this.newUser.employment_number = response.data.data.employment_number
                this.newUser.branch_id = response.data.data.branch[0].id
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        addNewUser(){
            this.postLoaded = true

            if(this.popUp_type == 'edit'){
                axios.put(`https://app.almujtama.com.sa/admin/admins/${this.user_id}`, this.newUser, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.addUser = !this.addUser
                    this.postLoaded = false
                    this.getAdmins();
                })  
                .catch(err =>  {
                    console.log(err.response.data.errors)
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;       
                })
            } else {
                axios.post(`https://app.almujtama.com.sa/admin/admins`, this.newUser, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.addUser = !this.addUser
                    this.postLoaded = false
                    this.getAdmins();
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;       
                })
            }
        },
        getRoles(){
            axios.get(`https://app.almujtama.com.sa/admin/roles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.roles = response.data.data
                this.loadingRequest = false;
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
         getBranches() {
            axios.get('https://app.almujtama.com.sa/admin/branch', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.branches = response.data.data
                this.options = response.data.data
                this.loadingRequest = false;
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        getAdmins(){
            Request.fetch('admin/admins', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.admins = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteUser(){
            Request.delete('admin/admins',this.deleteID)
            .then( () => {
                this.getAdmins();
            })
        },
        openAddUser(){
            this.popUp_type = 'add'
            this.addUser = !this.addUser
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getAdmins();
            } else{
                this.apiParams = `?page=${val}`;
                this.getAdmins();
            }
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.blueColor{
    color: #2081FF;
}

.add_user_container{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000032;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .add_user{
        background-color: #FFF;
        width: 482px;
        border-radius: 10px;
        padding: 30px 40px;
        overflow-y: scroll;
        margin: 50px 0;
        height: 90%;
        /* width */
        &::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgb(196, 190, 190);
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #78A28F;
        border-radius: 10px;
        }
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
                font-size: 18px;
                color: #769486;
                font-weight: 600;
            }
            svg{
                font-size: 30px;
                cursor: pointer;
            }
        }
        .body{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input_container{
                width: 48%;
                margin-bottom: 10px;
                @media (max-width: 991px) {
                    width: 100%;
                }
                label{
                    font-size: 16px;
                    font-weight: 600;
                    display: block;
                    margin-bottom: 5px;
                }
                input,
                select{
                    display: block;
                    border: 1px solid #DDD;
                    border-radius: 10px;
                    padding: 5px;
                    width: 100%;
                }
            }
        }
    }
}
</style>