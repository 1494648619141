<template>
    <div>
        <HeaderBg :img="img" title="المستخدمين" />
        <div class="content_container">
            <div class="labels">
                <div @click="label_name = 'userInfo'" :class="label_name == 'userInfo' ? 'label active_label' : 'label'">معلومات المستخدم</div>
                <!-- <div @click="label_name = 'address'" :class="label_name == 'address' ? 'label active_label' : 'label'">العناوين</div> -->
                <div @click="label_name = 'requests'" :class="label_name == 'requests' ? 'label active_label' : 'label'">الطلبات</div>
                <div class="label">النقاط</div>
            </div>
            <div class="form_container" v-if="label_name == 'userInfo'">
                <form action="" @submit.prevent="addUser">
                    <div class="first_section">
                        <div class="upload_img">
                            <span> الصورة الرئيسية</span>
                            <div class="img_container">
                                <img src="../../../assets/images/replaceImg.svg" alt="" v-if="imgUrl == ''">
                                <img :src="imgUrl" alt="" v-if="imgUrl !== ''">
                                <div class="photo_upload">
                                    <div class="upladImg">
                                        <div class="upload">
                                            <label for="">
                                                <img src="../../../assets/images/inputFile.svg" alt="">
                                                <input type="file" v-on="{ change: [uploadUserImg] }">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input_container">
                            <label>الاسم :</label>
                            <input type="text" v-model="user.name">
                        </div>
                        <div class="input_container" v-if="this.$route.params.id == undefined">
                            <label>الرقم السري :</label>
                            <input type="password" v-model="user.password">
                        </div>
                        <div class="input_container">
                            <label>نوع الحساب :</label>
                            <select v-model="user.role">
                                <option value="admin">مسئول</option>
                                <option value="customer">عميل</option>
                            </select>
                        </div>
                        <div class="input_container">
                            <label>رقم الجوال :</label>
                            <input type="text" v-model="user.mobile">
                        </div>
                        <div class="input_container">
                            <label>البريد الالكتروني :</label>
                            <input type="email" v-model="user.email">
                        </div>
                        <div class="input_container">
                            <label>تاريخ الميلاد :</label>
                            <date-picker v-model="user.birth" valueType="format"></date-picker>
                        </div>
                        <div class="input_container radio">
                            <label> الحالة : </label>
                            <select v-model="user.active" >
                                <option value="1">مفعل</option>
                                <option value="0">غير مفعل</option>
                            </select>
                        </div>
                        <div class="input_container radio">
                            <label> الجنس : </label>
                            <select v-model="user.gender" >
                                <option value="male">ذكر</option>
                                <option value="female">انثي</option>
                            </select>
                        </div>
                        <div class="input_container">
                            <label>المدينة :</label>
                            <input type="text" v-model="user.city">
                        </div>
                    </div>               
                    <div class="second_section">
                        <h3>الاسئلة الصحية</h3>
                        <div class="question">
                            <span>هل لديك حساسية من أي أدوية؟</span>
                            <input type="text" v-model="user.medicine_allergies">
                        </div>
                        <div class="question">
                            <span>هل لديك حساسية من أي أطعمة؟</span>
                            <input type="text" v-model="user.food_allergies">
                        </div>
                        <div class="question">
                            <span>هل لديك أي أمراض؟</span>
                            <input type="text" v-model="user.symptoms">
                        </div>
                    </div>
                 
                    <div class="alert alert-danger" role="alert" v-if="ErrorCheck == true">
                        <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                    </div>
                    <button class="saveBtn" v-if="postLoaded == false">
                        {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                    </button>
                    <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
                </form>
            </div>

            <div class="address_container" v-if="label_name == 'address'">
                <div class="saveBtn" @click="openAddUser"> إضافة عنوان </div>

                <div class="address">
                    <div class="addres">
                        <div class="address_name">
                            <span>العنوان</span>
                            <div> المنزل </div>
                        </div>
                        <div class="address_name">
                            <span>المدينة</span>
                            <div> جدة </div>
                        </div>
                        <div class="address_name">
                            <span>العنوان</span>
                            <div> حي الصفا جده 202215 </div>
                        </div>
                        <div class="actions">
                            <div class="delete">
                                <img src="../../../assets/images/deleteLogo.svg" alt="">
                            </div>
                            <div class="edit">
                                <img src="../../../assets/images/editLogo.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="requests_container" v-if="label_name == 'requests'">
                <UserRequests />
            </div>
            
            <div class="add_address" v-if="addUser_popUp == true">
                <div class="add_address_container">
                    <h3>إضافة عنوان جديدة</h3>
                    <div class="close_popUp" @click="openAddUser">
                        <b-icon icon="x" aria-hidden="true"></b-icon>
                    </div>

                    <form action="">
                        <div class="input_container">
                            <label> اسم العنوان </label>
                            <input type="text">
                        </div>
                        <div class="input_container">
                            <label> المدينة </label>
                            <input type="text">
                        </div>
                        <div class="input_container">
                            <label> العنوان </label>
                            <input type="text">
                        </div>
                        <div class="action">
                            <button class="saveBtn"> + اضافة </button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import axios from 'axios';
import UserRequests from './components/UserRequests'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name : 'AddUsers',
    components: {HeaderBg, UserRequests, DatePicker},
       data(){
        return{
            postLoaded: false,
            img: require('../../../assets/images/GroupWhite.png'),
            label_name: 'userInfo',
            addUser_popUp: false,
            imgUrl: '',
            user:{
                name: '',
                password: '',
                mobile: '',
                image: '',
                email: '',
                birth: '',
                role: '',
                gender: '',
                city: '',
                active: '',
                symptoms: '',
                medicine_allergies: '',
                food_allergies: '',
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getUser();
        
    },
    methods:{
        openAddUser(){
            this.addUser_popUp = !this.addUser_popUp
        },
        addUser(){
            this.postLoaded = true
            // this.error = {}
            const formData = new FormData();
            formData.append('image', this.user.image);
            formData.append('name', this.user.name);
            if(this.$route.params.id == undefined){
                formData.append('password', this.user.password);
            }
            formData.append('mobile', this.user.mobile);
            formData.append('email', this.user.email);
            formData.append('birth', this.user.birth);
            formData.append('gender', this.user.gender);
            formData.append('role', this.user.role);
            formData.append('city', this.user.city);
            formData.append('active', this.user.active);
            formData.append('food_allergies', this.user.food_allergies);
            formData.append('medicine_allergies', this.user.medicine_allergies);
            formData.append('symptoms', this.user.symptoms);
            if(this.$route.params.id !== undefined){
                formData.append('_method', 'PUT');
                axios.post(`https://app.almujtama.com.sa/admin/user/${this.$route.params.id}`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( res => {
                    this.$router.push('/store-admin/users')
                    console.log(res)
                    // this.error = {}
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    console.log(err.response.data.errors)
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post('https://app.almujtama.com.sa/admin/user', formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( res => {
                    this.$router.push('/store-admin/users')
                    console.log(res)
                    // this.error = {}
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    console.log(err.response.data.errors)
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            }
        },
        getUser(){
            if(this.$route.params.id !== undefined){
                axios.get(`https://app.almujtama.com.sa/admin/user/${this.$route.params.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Authorization': 'Bearer '+ localStorage.getItem('token'),
                    },
                })
                .then((response) => {
                    this.imgUrl = response.data.data.image   
                    this.user.name = response.data.data.name          
                    this.user.password = response.data.data.password          
                    this.user.mobile = response.data.data.mobile          
                    this.user.email = response.data.data.email          
                    this.user.birth = response.data.data.birth          
                    this.user.role = response.data.data.role          
                    this.user.gender = response.data.data.gender          
                    this.user.city = response.data.data.city          
                    this.user.active = response.data.data.active          
                })
                .catch((error) => {
                console.error('Error fetching data from API:', error);
                });
            }
        },
        uploadUserImg(e) {
            this.user.image = e.target.files[0];
            this.imgUrl = URL.createObjectURL(e.target.files[0]);
        },
        
    }
}
</script>
<style lang="scss" scoped>
.mx-datepicker {
    width: 100%;
}
    
.content_container{
    display: flex;
    justify-content: space-between;
    @media (max-width: 1400px) {
        padding: 0;
    }
    @media (max-width: 991px) {
        flex-direction: column;
    }
    .labels{
        background-color: #FFF;
        border-radius: 10px;
        padding: 30px 20px;
        width: 20%;
        height: 300px;
        @media (max-width: 991px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            height: unset;

        }
        > .label{
            color: #666666;
            font-weight: 18px;
            font-weight: 600;
            margin-bottom: 15px;
            cursor: pointer;
        }
    }
    .active_label{
        color: #78A28F !important;
    }
    .form_container{
        width: 77%;
        @media (max-width: 991px) {
            width: 100%;
        }
        form{
            .first_section,
            .second_section{
                display: flex;
                justify-content: space-between;
                background-color: #FFF;
                flex-wrap: wrap;
                border-radius: 10px;
                padding: 30px 20px;
                width: 100%;
                margin-bottom: 26px;
                box-shadow: 0px 3px 6px #00000029;
            }
            .second_section{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                > h3{
                    width: 100%;
                    font-size: 19px;
                    color: #656565;
                    font-weight: 600;
                    margin-bottom: 30px;
                }
                .question{
                    width: 30%;
                    @media (max-width: 576px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    span{
                        font-size: 16px;
                        font-weight: 800;
                        color: #656565;
                        display: block;
                        margin-bottom: 5px;
                    }
                    input{
                        background-color: #FFF;
                        border-radius: 10px;
                        padding: 5px 10px;
                        font-size: 14px;
                        border: 1px solid #C5C5C5;
                        width: 100%;
                    }
                    .checkBoxs{
                        display: flex;
                        align-items: center;
                        .check_container{
                            display: flex;
                            align-items: center;
                            margin-left: 20px;
                            label{
                                font-size: 16px;
                                font-weight: 600;
                                color: #656565;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
        .upload_img{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            span{
                font-size: 16px;
                color: #656565;
                margin-left: 50px;
            }
            .img_container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #FAFAFA;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img{
                    width: 100%;
                }
                .photo_upload{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    img{
                        width: 70%;
                    }
                }
            }
        }
        .input_container{
            margin-bottom: 16px;
            width: 30%;
            @media (max-width: 1440px) {
                width: 45%;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            label{
                font-size: 14px;
                white-space: nowrap;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            input,
            select,
            .b-form-datepicker{
                background-color: #FFF;
                border-radius: 10px;
                padding: 10px;
                font-size: 14px;
                border: 1px solid #C5C5C5;
                width: 100%;
                &::placeholder{
                    font-size: 14px;
                    color: #C5C5C5;
                }
            }
            .b-form-datepicker{
                padding: 5px;
            }
        }
    }
    .address_container{
        width: 77%;
        @media (max-width: 991px) {
            width: 100%;
        }
        > .saveBtn{
            margin: 0 !important;
        }
        .address{
            border-radius: 10px;
            margin-top: 19px;
            .addres{
                background-color: #FFF;
                border-radius: 10px;
                box-shadow: 0px 3px 6px #00000029;
                margin-bottom: 16px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 20px 10px;
                @media (max-width: 576px) {
                    flex-direction: column;
                }
                .address_name{
                    > span{
                        font-size: 16px;
                        color: #656565;
                        font-weight: 600;
                        margin-bottom: 10px;
                        display: block;
                    }
                    > div{
                        font-size: 16px;
                        color: #656565;
                        border: 1px solid #C5C5C5;
                        border-radius: 10px;
                        padding: 5px 20px;
                    }
                }
                .actions{
                    display: flex;
                    align-items: flex-end;
                    @media (max-width: 576px) {
                        margin-top: 30px;
                    }
                    .delete,
                    .edit{
                        background-color: #FF002B;
                        border-radius: 10px;
                        width: 40px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .edit{
                        background-color: #78A28F;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .add_address{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00000036;
        .add_address_container{
            width: 432px;
            height: 324px;
            background-color: #FFF;
            padding: 20px;
            position: relative;
            .close_popUp{
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 30px;
                cursor: pointer;
            }
            > h3{
                font-size: 16px;
                color: #78A28F;
                font-weight: 600;
            }
            form{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .input_container{
                    width: 45%;
                    margin-bottom: 30px;
                    label{
                        display: block;
                        font-size: 16px;
                        font-weight: 600;
                        color: #656565;
                    }
                    input{
                        font-size: 16px;
                        color: #656565;
                        border-radius: 10px;
                        border: 1px solid #C5C5C5;
                        padding: 10px 20px;
                        text-align: center;
                        width: 100%;
                    }
                }
                .action{
                    width: 100%;
                }
            }
        }
    }

    .saveBtn{
        margin: 0 auto 0 0;
        display: block;
    }
}
.radio{
    justify-content: flex-start !important;
    div{
        display: flex;
    }
}
</style>