<template>
    <div>
        <HeaderBg title="اللافتات" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeletePannar" 
            messege="هل أنت متأكد من مسح اللافتة ؟"
        />
        <header class="admin_content_header">
            <h2></h2>
            <div class="search">
                
            </div>
            <router-link to="/store-admin/pannars/add-pannars"> أضف لافتة جديد </router-link>
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="divsTable" v-if="loadingRequest == false">
            <div class="line_row">
                <h4>اللافتة الرئيسية</h4>
                <div class="actionsContainer">
                    <div class="options_container">
                        <img src="../../../assets/images/selectIcon.png" alt="">
                        <div class="hidden_options">
                            <button @click="() => editPannar(1)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                            <button @click="() => deleteData(1)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="line_row">
                <h4>لافتة الاعلانات</h4>
                <div class="actionsContainer">
                    <div class="options_container">
                        <img src="../../../assets/images/selectIcon.png" alt="">
                        <div class="hidden_options">
                            <button @click="() => editPannar(2)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                            <button @click="() => deleteData(1)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="line_row">
                <h4>لافتة العروض</h4>
                <div class="actionsContainer">
                    <div class="options_container">
                        <img src="../../../assets/images/selectIcon.png" alt="">
                        <div class="hidden_options">
                            <button @click="() => editPannar(3)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                            <!-- <button @click="() => deleteData(1)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="line_row">
                <h4>لافتة صفحة العروض</h4>
                <div class="actionsContainer">
                    <div class="options_container">
                        <img src="../../../assets/images/selectIcon.png" alt="">
                        <div class="hidden_options">
                            <button @click="() => editPannar(4)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                            <!-- <button @click="() => deleteData(1)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
// import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
// import store from "@/store/index.js";
export default {
    name: 'Pannars',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: false,
            deleteID: '',
            alertToggle: false,
        }
    },
    mounted(){
      
    },
    methods:{
        editPannar(id){
            this.$router.push(`/store-admin/panners/add-pannars/${id}`)
        },
            // delete data
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
    }
}
</script>
<style lang="scss" scoped>

</style>